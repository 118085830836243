import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultilangTextareaComponent } from './multilang-textarea.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UiInputModule } from '../ui-input/ui-input.module';
import { UiTextareaModule } from '../ui-textarea/ui-textarea.module';
import { EditorComponent } from "@tinymce/tinymce-angular";

@NgModule({
  declarations: [MultilangTextareaComponent],
  exports: [MultilangTextareaComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    UiInputModule,
    UiTextareaModule,
    EditorComponent,
  ],
})
export class MultilangTextareaModule {}
