<ng-container *ngIf="languages$ | async as languages; else preloader">
  <ng-container *ngIf="folderData$ | async as folderData; else preloader">
    <ui-form-report-folder
      [pending]="pending$ | async"
      [languages]="languages"
      [addMode]="false"
      [folder]="folder"
      [folderData]="folderData"
      (submitForm)="handlerEditFolder($event)"
    >
    </ui-form-report-folder>
  </ng-container>
</ng-container>

<ng-template #preloader>
  <ui-pre-loader [narrow]="true"></ui-pre-loader>
</ng-template>
