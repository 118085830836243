import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@popups/dialog/dialog.tokens';
import { ReportDashboardMode } from '@services/report-folder/report-folder.model';
import { ReportInfoModel } from '@services/report/report.model';
import { ReportService } from '@services/report/report.service';
import { DashboardService } from '@services/dashboard/dashboard.service';
import { Observable } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rep-popup-info-report',
  templateUrl: './popup-info-report.component.html',
  styleUrls: ['./popup-info-report.component.scss'],
})
export class PopupInfoReportComponent {
  readonly key: string = this.data.params?.serviceParams?.key;
  readonly mode: ReportDashboardMode = this.data.params?.serviceParams?.mode;
  readonly service: ReportService | DashboardService =
    this.mode === 'report' ? this.reportService : this.dashboardService;

  readonly description: Observable<ReportInfoModel> =
    this.service.getDescriptionById(this.key);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    private reportService: ReportService,
    private dashboardService: DashboardService,
  ) {}
}
