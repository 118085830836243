import { Pipe, PipeTransform } from '@angular/core';
import { toLower } from 'lodash-es';
import { AvailableLanguage } from '@core/localization/available-language';


@Pipe({
    name: 'convertLangForTinymce',
    standalone: true,
})
export class ConvertLangForTinymcePipe implements PipeTransform {
    public transform(lang: string): string {
        switch (toLower(lang)) {
            case AvailableLanguage.RuRu:
                return 'ru';
            case AvailableLanguage.EsEs:
                return 'es';
            default:
                return null;
        }
    }
}
