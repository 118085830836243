<ng-container *ngIf="reportDetail$ | async as reportDetail; else preloader">
  <ui-report-form
    [pending]="pending$ | async"
    [report]="reportDetail"
    [key]="report.key"
    [copyMode]="true"
    [languages]="languages$ | async"
    (submitForm)="handlerCopyReport($event)"
  ></ui-report-form>
</ng-container>

<ng-template #preloader>
  <ui-pre-loader [narrow]="true"></ui-pre-loader>
</ng-template>
