import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupAddFolderComponent } from './popup-add-folder/popup-add-folder.component';
import { PopupEditFolderComponent } from './popup-edit-folder/popup-edit-folder.component';
import { PopupsModule } from '@popups/popups.module';
import { PreLoaderModule } from '@ui/pre-loader/pre-loader.module';
import { FormReportFolderModule } from '@containers/form-report-folder/form-report-folder.module';
import { TranslateModule } from '@ngx-translate/core';
import { PopupCopyReportComponent } from './popup-copy-report/popup-copy-report.component';
import { ReportFormModule } from '@containers/report-form/report-form.module';
import { PopupInfoReportComponent } from './popup-info-report/popup-info-report.component';

@NgModule({
  declarations: [
    PopupAddFolderComponent,
    PopupEditFolderComponent,
    PopupCopyReportComponent,
    PopupInfoReportComponent,
  ],
  imports: [
    CommonModule,
    PopupsModule,
    PreLoaderModule,
    FormReportFolderModule,
    TranslateModule,
    ReportFormModule,
  ],
})
export class PopupContainersModule {}
