import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { LangModel } from '@libs/lang/lang.model';
import { ReportBody, ReportDetailModel } from '@services/report/report.model';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MultilangInputModel } from '@ui/multilang-input/multilang-input.model';
import { ReportFolderModel } from '@services/report-folder/report-folder.model';
import { ReportRoleModel } from '@services/report-role/report-role.model';
import { CommonTreeFactory } from '@libs/factory/tree/tree.factory';
import { LangService } from '@libs/lang/lang.service';

/**
 *  Form for add / edit report
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss'],
})
export class ReportFormComponent {
  /**
   * Copy mode flag
   */
  @Input() copyMode: boolean;
  /**
   * Report key id
   */
  @Input() key: string;
  /**
   * Read-only mode flag
   */
  @Input() lyView: boolean;
  /**
   * Server pending flag
   */
  @Input() pending: boolean;
  /**
   * List languages
   */
  @Input() languages: LangModel[];
  /**
   * Roles list
   */
  @Input() roles: ReportRoleModel[];
  /**
   * Submit form event
   *
   * @returns Form data
   */
  @Output() submitForm: EventEmitter<ReportBody> =
    new EventEmitter<ReportBody>();
  /**
   * Form object
   */
  readonly form = this.fb.group(
    {
      names: this.fb.control(null, [Validators.required]),
      folders: this.fb.control(null, []),
      roles: this.fb.control(null, []),
      infos: this.fb.control(null, []),
    },
    {},
  );
  /**
   * Name Multilang patch variable
   */
  names: MultilangInputModel[];
  /**
   * Description Multilang patch variable
   */
  descriptions: MultilangInputModel[];

  /**
   * Folder array with level
   */
  displayFolders: unknown[];

  lang: string = this.langService.currentLangCode;

  constructor(private fb: FormBuilder, private langService: LangService) {}
  /**
   * Folders list
   */
  @Input() set folders(folders: ReportFolderModel[]) {
    if (folders) {
      const tree = new CommonTreeFactory(folders, 'folderId', 'pFolderId');
      tree.makeTree();
      tree.makeSortList();
      this.displayFolders = tree.list;
    }
  }

  /**
   * Report data for form patch
   */
  @Input() set report(report: ReportDetailModel) {
    if (report) {
      this.form.patchValue({
        names: report.names,
        infos: report.infos,
        folders: report.folders?.map((item) => item.folderId),
        roles: report.roles?.map((item) => item.roleId),
      });
      this.names = report.names;
      this.descriptions = report.infos;
    }
  }

  handlerSubmit(): void {
    const bodyForm = this.form.getRawValue();
    const names = bodyForm.names.map((item: MultilangInputModel) => {
      const description = bodyForm.infos.find(
        (d: MultilangInputModel) =>
          d.lang.toLowerCase() === item.lang.toLowerCase(),
      );
      return {
        lang: item.lang.toUpperCase(),
        value: item.value,
        info: description ? description.value : '',
      };
    });

    if (this.copyMode) {
      this.submitForm.emit({
        ...{ ...bodyForm, ...{ names } },
        ...{ key: this.key },
      });
    } else {
      this.submitForm.emit({ ...bodyForm, ...{ names } });
    }
  }
}
