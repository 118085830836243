import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiConfigService } from '@libs/api-config/api-config.service';
import {
  ReportFolderBody,
  ReportFolderDetail,
  ReportFolderModel,
} from '@services/report-folder/report-folder.model';
import {
  catchError,
  finalize,
  map,
  publishReplay,
  refCount,
  repeatWhen,
  tap,
} from 'rxjs/operators';
import { ErrorResponseModel } from '@libs/error/error.model';

/**
 * Service for dashboard folders work
 */
@Injectable({
  providedIn: 'root',
})
export class DashboardFolderService {
  private readonly pending$: Subject<boolean> = new Subject();
  private readonly refresh$: Subject<unknown> = new Subject();

  constructor(
    private http: HttpClient,
    private apiConfigService: ApiConfigService,
  ) {}

  get pending(): Observable<boolean> {
    return this.pending$;
  }

  /**
   * Getting a tree of folders for dashboards
   */
  list(): Observable<ReportFolderModel[]> {
    return this.http
      .get<ReportFolderModel[]>(
        this.apiConfigService.getMethodUrl(
          'boservice.analyticapi.dashboardfolderlist',
        ),
      )
      .pipe(
        repeatWhen(() => this.refresh$),
        map((items) =>
          items.map((item) => {
            /**
             * For tree factory
             */
            if (item.pFolderId === 0) {
              return { ...item, ...{ pFolderId: null } };
            }
            return item;
          }),
        ),
        publishReplay(1),
        refCount(),
      );
  }

  /**
   * Adding a Reports Folder
   *
   * @param body
   */
  add(body: ReportFolderBody): Observable<unknown> {
    this.pending$.next(true);
    return this.http
      .post(
        this.apiConfigService.getMethodUrl(
          'boservice.analyticapi.dashboardfolderadd',
        ),
        body,
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        tap((_) => this.refresh$.next(null)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      );
  }

  /**
   * Editing the Dashboard folder
   *
   * @param body
   */
  edit(body: ReportFolderBody): Observable<unknown> {
    this.pending$.next(true);
    return this.http
      .post(
        this.apiConfigService.getMethodUrl(
          'boservice.analyticapi.dashboardfolderset',
        ),
        body,
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        tap((_) => this.refresh$.next(null)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      );
  }

  /**
   * Deleting a Dashboard folder
   *
   * @param folderId
   */
  delete(folderId: number): Observable<unknown> {
    this.pending$.next(true);
    return this.http
      .delete(
        this.apiConfigService.getMethodUrl(
          'boservice.analyticapi.dashboardfolderdelete',
        ),
        {
          params: { folderId },
        },
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        tap((_) => this.refresh$.next(null)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      );
  }

  /**
   * Get detail data folder
   *
   * @param folderId - folder Id
   */
  getById(folderId: number): Observable<ReportFolderDetail> {
    this.pending$.next(true);
    return this.http
      .get(
        this.apiConfigService.getMethodUrl(
          'boservice.analyticapi.dashboardfolderget',
        ),
        {
          params: {
            folderId,
          },
        },
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      ) as Observable<ReportFolderDetail>;
  }

  private errorHandler(error: ErrorResponseModel): any {
    this.pending$.next(false);
    throw error;
  }
}
