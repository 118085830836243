import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CookieMessageComponent } from '@ui/cookie-message/cookie-message.component';
import { HeaderComponent } from '@ui/header/header.component';
import { FooterComponent } from '@ui/footer/footer.component';
import { NavbarComponent } from '@ui/navbar/navbar.component';
import { CookiePolicyTextComponent } from '@ui/cookie-policy-text/cookie-policy-text.component';
import { AuthGuard } from '@libs/auth/auth.guard';
import { AuthWithTempPasswordGuard } from '@libs/auth/auth-with-temp-password.guard';
import { submenu } from './sub.menu';
import { ReportResolverService } from '@services/resolvers/report-resolver.service';
import { RouteEntity } from '@core/router/route.entity';
import { MenuItemsModel } from '@libs/menu/menu.model';

const dataPrivilege: Record<'moduleMenu', MenuItemsModel[]> = {
    moduleMenu: submenu,
};

const routes: Routes = [
  {
    path: RouteEntity.Empty,
    component: CookieMessageComponent,
    outlet: 'cookie-message',
  },
  {
    path: RouteEntity.Empty,
    component: HeaderComponent,
    outlet: 'header',
  },
  {
    path: RouteEntity.Empty,
    component: FooterComponent,
    outlet: 'footer',
  },
  {
    path: RouteEntity.Empty,
    component: NavbarComponent,
    outlet: 'navbar',
    data: {
      subMenuItems: submenu,
    },
  },
  {
    path: RouteEntity.Empty,
    redirectTo: RouteEntity.Reports,
    pathMatch: 'full',
  },
  {
    path: RouteEntity.CookiePolicy,
    component: CookiePolicyTextComponent,
  },
  {
    path: RouteEntity.ReportsEditor + '/' + RouteEntity.Add,
    loadChildren: () =>
      import('./pages/page-report-add/page-report-add.module').then(
        (m) => m.PageReportAddModule,
      ),
    canLoad: [AuthGuard],
    data: dataPrivilege,
  },
  {
    path: RouteEntity.ReportsEditor + '/:key',
    loadChildren: () =>
      import('./pages/page-report/page-report.module').then(
        (m) => m.PageReportModule,
      ),
    canLoad: [AuthGuard],
    data: dataPrivilege,
    resolve: {
      report: ReportResolverService,
    },
  },
  {
    path: RouteEntity.ReportsEditor,
    loadChildren: () =>
      import('./pages/page-reports-editor/page-reports-editor.module').then(
        (m) => m.PageReportsEditorModule,
      ),
    canLoad: [AuthGuard],
    data: dataPrivilege,
  },
  {
    path: RouteEntity.Reports + '/' + RouteEntity.View + '/:key',
    loadChildren: () =>
      import('./pages/page-report-view/page-report-view.module').then(
        (m) => m.PageReportViewModule,
      ),
    canLoad: [AuthGuard],
  },
  {
    path: RouteEntity.Reports,
    loadChildren: () =>
      import('./pages/page-reports/page-reports.module').then(
        (m) => m.PageReportsModule,
      ),
    canLoad: [AuthGuard],
    data: dataPrivilege,
  },
  {
    path: RouteEntity.Dashboards,
    loadChildren: () =>
      import('./pages/page-dashboards/page-dashboards.module').then(
        (m) => m.PageDashboardsModule,
      ),
    canLoad: [AuthGuard],
    data: dataPrivilege,
  },
  {
    path: RouteEntity.DashboardsEditor + '/' + RouteEntity.Add,
    loadChildren: () =>
      import('./pages/page-dashboard-add/page-dashboard-add.module').then(
        (m) => m.PageDashboardAddModule,
      ),
    canLoad: [AuthGuard],
    data: dataPrivilege,
  },
  {
    path: RouteEntity.DashboardsEditor + '/:key',
    loadChildren: () =>
      import('./pages/page-dashboard/page-dashboard.module').then(
        (m) => m.PageDashboardModule,
      ),
    canLoad: [AuthGuard],
    data: dataPrivilege,
  },
  {
    path: RouteEntity.DashboardsEditor,
    loadChildren: () =>
      import(
        './pages/page-dashboards-editor/page-dashboards-editor.module'
      ).then((m) => m.PageDashboardsEditorModule),
    canLoad: [AuthGuard],
    data: dataPrivilege,
  },
  {
    path: RouteEntity.Reports + '/' + RouteEntity.View + '/:key',
    loadChildren: () =>
      import('./pages/page-report-view/page-report-view.module').then(
        (m) => m.PageReportViewModule,
      ),
    canLoad: [AuthGuard],
  },
  {
    path: RouteEntity.Dashboards + '/' + RouteEntity.View + '/:key',
    loadChildren: () =>
      import('./pages/page-dashboard-view/page-dashboard-view.module').then(
        (m) => m.PageDashboardViewModule,
      ),
    canLoad: [AuthGuard],
  },
  {
    path: RouteEntity.LoadData,
    loadChildren: () =>
      import('./pages/page-load-data/page-load-data.module').then(
        (m) => m.PageLoadDataModule,
      ),
    canLoad: [AuthGuard],
    data: dataPrivilege,
  },
  {
    path: RouteEntity.Logout,
    loadChildren: () =>
      import('@containers/page-logout/page-logout.module').then(
        (m) => m.PageLogoutModule,
      ),
    canLoad: [AuthGuard],
  },
  {
    path: RouteEntity.Login,
    loadChildren: () =>
      import('@containers/page-login/page-login.module').then(
        (m) => m.PageLoginModule,
      ),
  },
  {
    path: RouteEntity.UserSettings,
    loadChildren: () =>
      import('@containers/page-settings/page-settings.module').then(
        (m) => m.PageSettingsModule,
      ),
    canLoad: [AuthWithTempPasswordGuard],
  },
  {
    path: RouteEntity.Error + '/:code',
    loadChildren: () =>
      import('@containers/error-container/error-container.module').then(
        (m) => m.ErrorContainerModule,
      ),
  },
  {
    path: '**',
    redirectTo: RouteEntity.Error + '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
