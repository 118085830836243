import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportFormComponent } from './report-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MultilangInputModule } from '@ui/multilang-input/multilang-input.module';
import { ButtonRowModule } from '@ui/button-row/button-row.module';
import { ButtonModule } from '@ui/button/button.module';
import { FormGroupWrapperModule } from '@ui/form-group-wrapper/form-group-wrapper.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { MultilangTextareaModule } from '@ui/multilang-textarea/multilang-textarea.module';
import { ConvertLangForTinymcePipe } from '@pipes/convert-lang-for-tinymce/convert-lang-for-tinymce.pipe';


@NgModule({
  declarations: [ReportFormComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MultilangInputModule,
        ButtonRowModule,
        ButtonModule,
        FormGroupWrapperModule,
        NgSelectModule,
        TranslateModule,
        EditorComponent,
        MultilangTextareaModule,
        ConvertLangForTinymcePipe,
    ],
  exports: [ReportFormComponent],
})
export class ReportFormModule {}
