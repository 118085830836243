<form [formGroup]="form">
  <ui-multilang-input
    customClass="mb-0"
    [label]="'global.forms.labels.name'"
    [languages]="languages"
    [formControlName]="'names'"
    [patch]="names"
    [disabledControl]="lyView"
    [required]="true"
  ></ui-multilang-input>

  <ui-button-row [lyView]="lyView">
    <ui-button
      [size]="'lg'"
      [disabled]="form?.status === 'INVALID' || pending || !form?.dirty"
      [label]="addMode ? 'global.actions.add' : 'global.labels.save'"
      (handlerClick)="handlerSubmit()"
    >
    </ui-button>
  </ui-button-row>
</form>
