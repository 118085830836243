import { MenuItemsModel } from '@libs/menu/menu.model';
import {
  BO_DASHBOARD_MODULE_CODE,
  BO_LOAD_DATA_MODULE_CODE,
  BO_REP_MODULE_CODE,
} from './app.constants';
import { RouteEntity } from "@core/router/route.entity";

export const submenu: MenuItemsModel[] = [
  {
    mdId: 1,
    mdName: 'reports.head',
    url: RouteEntity.Root + RouteEntity.Reports,
    objName: [BO_REP_MODULE_CODE],
  },
  {
    mdId: 2,
    mdName: 'reports-editor.head',
    url: RouteEntity.Root + RouteEntity.ReportsEditor,
    objName: [BO_REP_MODULE_CODE],
    needPrivilegeForView: [{ [BO_REP_MODULE_CODE]: ['edit'] }],
  },
  {
    mdId: 3,
    mdName: 'dashboards.head',
    url: RouteEntity.Root + RouteEntity.Dashboards,
    objName: [BO_DASHBOARD_MODULE_CODE],
  },
  {
    mdId: 4,
    mdName: 'dashboards-editor.head',
    url: RouteEntity.Root + RouteEntity.DashboardsEditor,
    objName: [BO_DASHBOARD_MODULE_CODE],
    needPrivilegeForView: [{ [BO_DASHBOARD_MODULE_CODE]: ['edit'] }],
  },
  {
    mdId: 5,
    mdName: 'load-data.head',
    url: RouteEntity.Root + RouteEntity.LoadData,
    objName: [BO_LOAD_DATA_MODULE_CODE],
  },
];

export const thirdLevelMenu: Record<string, MenuItemsModel[]> = {
  dashboard: [
    {
      mdId: 1,
      mdName: 'global.labels.info',
      urlTemplate: RouteEntity.Root + RouteEntity.DashboardsEditor + '/#key#/' + RouteEntity.Info,
    },
    {
      mdId: 2,
      mdName: 'dashboards-editor.translate',
      urlTemplate: RouteEntity.Root + RouteEntity.DashboardsEditor + '/#key#/' + RouteEntity.Resources,
    },
  ],
};
