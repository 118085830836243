import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiConfigService } from '@libs/api-config/api-config.service';
import {
  ReportBody,
  ReportDetailModel,
  ReportDisplayTreeItemModel, ReportInfoModel,
  ReportModel
} from "@services/report/report.model";
import {
  catchError,
  finalize,
  map,
  publishReplay,
  refCount,
  repeatWhen,
  tap,
} from 'rxjs/operators';
import { ErrorResponseModel } from '@libs/error/error.model';

/**
 * Reports service
 */
@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private readonly pending$: Subject<boolean> = new Subject();
  private readonly refresh$: Subject<unknown> = new Subject();

  constructor(
    private http: HttpClient,
    private apiConfigService: ApiConfigService,
  ) {}

  get pending(): Observable<boolean> {
    return this.pending$;
  }

  /**
   * Getting reports along with the folder tree
   *
   * Returns only those reports for which the current user has rights. Empty folders (which do not contain any dashboards/reports in themselves and in the underlying folders) are not returned.
   */
  displayTree(): Observable<ReportDisplayTreeItemModel[]> {
    return this.http
      .get<ReportDisplayTreeItemModel[]>(
        this.apiConfigService.getMethodUrl(
          'boservice.analyticapi.reportmenulist',
        ),
      )
      .pipe(
        repeatWhen(() => this.refresh$),
        map((items) =>
          items.map((item) => {
            /** Modify response for tree factory */
            if (item.pFolderId === 0) {
              return { ...item, ...{ pFolderId: null } };
            }

            if (item.folderId === null) {
              return { ...item, ...{ folderId: -1 } };
            }
            return item;
          }),
        ),
        publishReplay(),
        refCount(),
      );
  }

  /**
   * Getting a list of reports
   * Getting a list of reports for editing. Returns only those reports for which the current user has rights
   */
  list(): Observable<ReportModel[]> {
    return this.http
      .get<ReportModel[]>(
        this.apiConfigService.getMethodUrl('boservice.analyticapi.reportlist'),
      )
      .pipe(
        repeatWhen(() => this.refresh$),
        publishReplay(),
        refCount(),
      );
  }

  /**
   * Create a report
   *
   * @param body
   */
  add(body: ReportBody): Observable<unknown> {
    this.pending$.next(true);
    return this.http
      .post(
        this.apiConfigService.getMethodUrl('boservice.analyticapi.reportadd'),
        body,
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        tap((_) => this.refresh$.next(null)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      );
  }

  /**
   * Copy a report
   *
   * @param body
   */
  copy(body: ReportBody): Observable<unknown> {
    this.pending$.next(true);
    return this.http
      .post(
        this.apiConfigService.getMethodUrl(
          'boservice.analyticapi.reportcopyadd',
        ),
        body,
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        tap((_) => this.refresh$.next(null)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      );
  }

  /**
   * Editing a Report
   *
   * @param body
   */
  edit(body: ReportBody): Observable<unknown> {
    this.pending$.next(true);
    return this.http
      .post(
        this.apiConfigService.getMethodUrl('boservice.analyticapi.reportset'),
        body,
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        tap((_) => this.refresh$.next(null)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      );
  }

  /**
   * key
   *
   * @param key
   */
  delete(key: string): Observable<unknown> {
    this.pending$.next(true);
    return this.http
      .delete(
        this.apiConfigService.getMethodUrl(
          'boservice.analyticapi.reportdelete',
        ),
        {
          params: { key },
        },
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        tap((_) => this.refresh$.next(null)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      );
  }

  /**
   * Get detail data report
   *
   * @param key - report key
   */
  getById(key: string): Observable<ReportDetailModel> {
    this.pending$.next(true);
    return this.http
      .get(
        this.apiConfigService.getMethodUrl('boservice.analyticapi.reportget'),
        {
          params: {
            key,
          },
        },
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      ) as Observable<ReportDetailModel>;
  }

  /**
   * Get description report
   * @param key - report key
   */
  getDescriptionById(key: string): Observable<ReportInfoModel> {
    this.pending$.next(true);
    return this.http
      .get(
        this.apiConfigService.getMethodUrl('boservice.analyticapi.reportinfoget'),
        {
          params: {
            key,
          },
        },
      )
      .pipe(
        finalize(() => this.pending$.next(false)),
        catchError((error: ErrorResponseModel) => this.errorHandler(error)),
      ) as Observable<ReportInfoModel>;
  }

  private errorHandler(error: ErrorResponseModel): any {
    this.pending$.next(false);
    throw error;
  }
}
