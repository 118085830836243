<form class="row" [formGroup]="form">
  <div class="col-12">
    <div class="d-flex flex-row align-items-center">
      <label class="label" *ngIf="label"
      >{{ label | translate }}<sup *ngIf="required">*</sup></label
      >
      <div class="lang-checker ms-6">
        <span
          class="pseudo pseudo--border-off pseudo--black me-3"
          *ngFor="let lang of languagesValue"
          [class.pseudo--selected]="selectedLang === lang.code"
          (click)="selectedLang = lang.code"
        >{{ lang.code }}</span
        >
      </div>
    </div>
    <div class="position-relative">
      <ng-container *ngIf="!editorEnable else editor">
        <ui-textarea
          class="mb-0"
          *ngFor="let lang of languagesValue"
          [required]="required"
          [disabledControl]="disabledControl"
          [class.d-none]="lang.code !== selectedLang"
          [class.d-block]="lang.code === selectedLang"
          [filler]="lang.code"
          [formControlName]="lang.code"
        >
        </ui-textarea>
      </ng-container>
      <ng-template #editor>
        <editor
          [class.d-none]="lang.code !== selectedLang"
          [class.d-block]="lang.code === selectedLang"
          class="mb-0"
          *ngFor="let lang of languagesValue"
          [formControlName]="lang.code"
          [init]="{base_url: '/tinymce',suffix: '.min', plugins: 'table wordcount link',
          language: langForEditor}"></editor>
      </ng-template>

    </div>
  </div>
</form>
