import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ReportService } from '@services/report/report.service';
import { ReportDetailModel } from '@services/report/report.model';
import { isNil } from "lodash-es";

@Injectable({
  providedIn: 'root',
})
export class ReportResolverService {
  constructor(private reportServices: ReportService) {}
  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<ReportDetailModel> | Observable<never> {
    const key: string = route.paramMap.get('key');
    return isNil(key) ? null : this.reportServices.getById(key).pipe(take(1));
  }
}
