import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    ReportDashboardMode,
    ReportFolderBody,
    ReportFolderDetail,
    ReportFolderModel,
} from '@services/report-folder/report-folder.model';
import { Observable } from 'rxjs';
import { LangModel } from '@libs/lang/lang.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@popups/dialog/dialog.tokens';
import { LangService } from '@libs/lang/lang.service';
import { ReportFolderService } from '@services/report-folder/report-folder.service';
import { DashboardFolderService } from '@services/dashboard-folder/dashboard-folder.service';
import { PushService } from '@core/push/push.service';
import { PushCodeModel } from '@core/push/push-code.model';


@UntilDestroy()
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'rep-popup-edit-folder',
    templateUrl: './popup-edit-folder.component.html',
    styleUrls: ['./popup-edit-folder.component.scss'],
})
export class PopupEditFolderComponent {
    readonly #push: PushService = inject(PushService);

    folder: ReportFolderModel = this.data.params?.serviceParams?.folder;
    mode: ReportDashboardMode = this.data.params?.serviceParams?.mode;
    service: ReportFolderService | DashboardFolderService =
        this.mode === 'report'
            ? this.reportFolderService
            : this.dashboardFolderService;
    folderData$: Observable<ReportFolderDetail> = this.service.getById(
        this.folder.folderId,
    );
    languages$: Observable<LangModel[]> = this.langService.languages;
    pending$: Observable<boolean> = this.service.pending;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        private dialogRef: MatDialogRef<PopupEditFolderComponent>,
        private langService: LangService,
        private reportFolderService: ReportFolderService,
        private dashboardFolderService: DashboardFolderService,
    ) {
    }

    handlerEditFolder(body: ReportFolderBody): void {
        this.service
            .edit(body)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.#push.success(PushCodeModel.SuccessAdd);
                this.dialogRef.close();
            });
    }
}
