// export const PATH_ROOT = '/';
// export const PATH_REPORTS_EDITOR = 'reports-editor';
// export const PATH_REPORTS = 'reports';
// export const PATH_VIEW = 'view';
// export const PATH_DASHBOARDS = 'dashboards';
// export const PATH_DASHBOARDS_EDITOR = 'dashboards-editor';
// export const PATH_LOAD_DATA = 'load-data';

export const BO_REP_MODULE_CODE = 'bo.anman.reports';
export const BO_DASHBOARD_MODULE_CODE = 'bo.anman.dashboard';
export const BO_LOAD_DATA_MODULE_CODE = 'bo.reports.load';

export const REPORT_DEV_EXPRESS_PATH = '/Report/Default/Report/';
export const DASHBOARD_DEV_EXPRESS_PATH = '/Dashboard/Default/Dashboard/';
