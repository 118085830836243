import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormReportFolderComponent } from './form-report-folder.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MultilangInputModule } from '@ui/multilang-input/multilang-input.module';
import { ButtonRowModule } from '@ui/button-row/button-row.module';
import { ButtonModule } from '@ui/button/button.module';

@NgModule({
  declarations: [FormReportFolderComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MultilangInputModule,
    ButtonRowModule,
    ButtonModule,
  ],
  exports: [FormReportFolderComponent],
})
export class FormReportFolderModule {}
