<ng-container *ngIf="languages$ | async as languages; else preloader">
  <h2 class="mb-3">{{ 'containers.popup-add-folder.head' | translate }}</h2>
  <ui-form-report-folder
    [pending]="pending$ | async"
    [languages]="languages"
    [addMode]="true"
    [folder]="folder"
    (submitForm)="handlerAddFolder($event)"
  >
  </ui-form-report-folder>
</ng-container>

<ng-template #preloader>
  <ui-pre-loader></ui-pre-loader>
</ng-template>
