import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { CommonAppModule } from '@ui/common-app/common-app.module';
import { SharedCommonModule } from '@libs/shared-common/shared-common.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  BsDatePickerDeps,
  DomDeps,
  HttpTranslateLoaderFactory,
  startupAppConfigs,
  startupDeps,
} from '@core/app/app.module.helpers';
import { HttpBackend } from "@angular/common/http";
import { ToastrModule } from 'ngx-toastr';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { StatisticsModule } from '../../../statistics/src/lib/statistics.module';
import { CommonAppComponent } from '@ui/common-app/common-app.component';
import { PopupContainersModule } from './popup-containers/popup-containers.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ThirdLevelMenuFactory } from '@libs/menu/third-level-menu.service';
import { thirdLevelMenu } from './sub.menu';
import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import { DefaultPushConfig } from '@core/push/push.config';


export const loadSubMenu =
  (thirdLevelMenuFactory: ThirdLevelMenuFactory) => () =>
    thirdLevelMenuFactory.loadMenu(thirdLevelMenu);

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonAppModule,
    AppRoutingModule,
    SharedCommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpTranslateLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    ToastrModule.forRoot(DefaultPushConfig),
    NgxWebstorageModule.forRoot(),
    StatisticsModule,
    PopupContainersModule,
    BsDatepickerModule.forRoot(),
    EditorModule
  ],
  providers: [
    ...DomDeps,
    ...BsDatePickerDeps,
    ...[
      { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
      {
        provide: APP_INITIALIZER,
        useFactory: startupAppConfigs,
        deps: startupDeps,
        multi: true,
      },
      {
        provide: APP_INITIALIZER,
        useFactory: loadSubMenu,
        deps: [ThirdLevelMenuFactory],
        multi: true,
      },
    ],
  ],
  bootstrap: [CommonAppComponent],
})
export class AppModule {}
