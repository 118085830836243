import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { LangModel } from '@libs/lang/lang.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@popups/dialog/dialog.tokens';
import { LangService } from '@libs/lang/lang.service';
import { ReportDashboardMode, ReportFolderBody, ReportFolderModel } from '@services/report-folder/report-folder.model';
import { ReportFolderService } from '@services/report-folder/report-folder.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DashboardFolderService } from '@services/dashboard-folder/dashboard-folder.service';
import { PushCodeModel } from '@core/push/push-code.model';
import { PushService } from '@core/push/push.service';


@UntilDestroy()
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'rep-popup-add-folder',
    templateUrl: './popup-add-folder.component.html',
    styleUrls: ['./popup-add-folder.component.scss'],
})
export class PopupAddFolderComponent {
    readonly #push: PushService = inject(PushService);

    public folder: ReportFolderModel = this.data.params?.serviceParams?.folder;
    public mode: ReportDashboardMode = this.data.params?.serviceParams?.mode;
    public service: ReportFolderService | DashboardFolderService =
        this.mode === 'report'
            ? this.reportFolderService
            : this.dashboardFolderService;
    public languages$: Observable<LangModel[]> = this.langService.languages;
    public pending$: Observable<boolean> = this.service.pending;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        private dialogRef: MatDialogRef<PopupAddFolderComponent>,
        private langService: LangService,
        private reportFolderService: ReportFolderService,
        private dashboardFolderService: DashboardFolderService,
    ) {
    }

    public handlerAddFolder(body: ReportFolderBody): void {
        this.service
            .add(body)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.#push.success(PushCodeModel.SuccessAdd);
                this.dialogRef.close();
            });
    }
}
